import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Mousewheel, FreeMode } from 'swiper/modules';
import { buildClassName } from 'utils/build-class-name';
import ListingCard, { ListingCardData } from 'components/listing-card';
import CTACard from 'components/home-page/home-modules/cta-card';
import { listingCardIds } from 'constants/test-constants';
import styles from './style.module.scss';
import useSizeClass, { useIsMobile } from 'hooks/use-size-class';
import { trackEvent } from 'utils/google-tag-manager';
import { GTM_CLICK_HOMEPAGE_SWIPER_SALE, GTM_CLICK_HOMEPAGE_SWIPER_SOLD, GTM_CLICK_HOMEPAGE_SWIPER_FEATURED, GTM_CLICK_HOMEPAGE_SALE, GTM_CLICK_HOMEPAGE_SOLD, GTM_CLICK_HOMEPAGE_FEATURED } from 'constants/events';
import CarouselArrows from 'components/swiper-carousel/arrows';

import type { IUserLocation } from 'utils/user-location';
import type SwiperClass from 'swiper/types/swiper-class';

interface Props {
  listingCardsData?: {
    listings: ListingCardData[];
    type?: string;
    userLocation?: IUserLocation | null;
    isCTAIncluded?: boolean;
    redirectUrl?: string;
  };
  listingToCompare?: ListingCardData;
  isCrawler?: boolean;
  isHomepageAbTesting?: boolean;
  gtmOnClickEvent?: string;
}

const DESKTOP_INDEX_LOAD_START = 2;
const DESKTOP_INDEX_LOAD_END = 4;
const DESKTOP_INDEX_LARGE_LOAD_END = 7;

export default function ListingCardCarousel({ listingCardsData, listingToCompare, isCrawler, isHomepageAbTesting, gtmOnClickEvent }: Props) {
  const { listings, type, userLocation, isCTAIncluded, redirectUrl } = listingCardsData || {};
  const [swiper, setSwiper] = useState<SwiperClass>();
  const [carouselItems, setCarouselItems] = useState<JSX.Element[]>([]);
  const [initialSlideChangeDone, setInitialSlideChangeDone] = useState(false);
  const [isLoopOn, setIsLoopOn] = useState(false);
  const isMobile = useIsMobile();
  const isLargeDesktop = useSizeClass({ min: 1500 });
  const calculatedDesktopLoadEnd = isLargeDesktop ? DESKTOP_INDEX_LARGE_LOAD_END : DESKTOP_INDEX_LOAD_END;
  const listingsCount = (listings || []).length;
  const shouldLoopBeEnabled = (listings || []).length >= 3;
  const isNotFull = (listings || []).length <= 3 && useSizeClass({ min: 1000 });

  const handleScrollEvents = () => {
    if (!initialSlideChangeDone) {
      setInitialSlideChangeDone(true);
    } else {
      if (type == 'available') {
        trackEvent(GTM_CLICK_HOMEPAGE_SWIPER_SALE);
      } else if (type == 'sold') {
        trackEvent(GTM_CLICK_HOMEPAGE_SWIPER_SOLD);
      } else if (type == 'featured') {
        trackEvent(GTM_CLICK_HOMEPAGE_SWIPER_FEATURED);
      }
    }
  };

  const handleClickEvents = () => {
    if (type === 'available') {
      trackEvent(GTM_CLICK_HOMEPAGE_SALE);
    } else if (type === 'sold') {
      trackEvent(GTM_CLICK_HOMEPAGE_SOLD);
    } else if (type == 'featured') {
      trackEvent(GTM_CLICK_HOMEPAGE_FEATURED);
    }
  };

  const slideTo = (slide: 'next' | 'prev' | number) => {
    if (shouldLoopBeEnabled && slide === 'prev' && swiper?.activeIndex === 0) {
      setIsLoopOn(true);
    }

    const timeout = shouldLoopBeEnabled && !isLoopOn ? 100 : 0;

    // Initial timeout to let slider know loop is on
    setTimeout(() => {
      slide === 'next' && swiper?.slideNext();
      slide === 'prev' && swiper?.slidePrev();
    }, timeout);
  };

  const loadRestOfItems = (swiper: SwiperClass) => {
    const sliceIndex = !isMobile ? calculatedDesktopLoadEnd : DESKTOP_INDEX_LOAD_START;
    if (!carouselItems.length && listingsCount > sliceIndex) {
      const items = listings!.slice(sliceIndex).map((listing, index) => (
        <SwiperSlide virtualIndex={index} key={listing.id} className={buildClassName(styles.card, 'class'+index)}>
          <ListingCard listing={listing} listingToCompare={listingToCompare} isCrawler={index < 2 && isCrawler} isHomepageAbTesting={isHomepageAbTesting} gtmOnClickEvent={gtmOnClickEvent} />
        </SwiperSlide>
      ));
      if (isCTAIncluded) {
        items.push(
          <SwiperSlide virtualIndex={items.length} key={items.length} className={styles.card}>
            <CTACard key={items.length} type={type!} userLocation={userLocation!} redirectUrl={redirectUrl} />
          </SwiperSlide>
        );
      }
      setCarouselItems(items);
    }

    if (shouldLoopBeEnabled && (swiper.isBeginning || swiper.isEnd)) {
      setTimeout(() => setIsLoopOn(true), 600);
    }
  };

  return (
    <div className={styles.component} data-testid={listingCardIds.listingsSwiper}>
      <Swiper
        onSwiper={setSwiper}
        className={buildClassName(styles.slider)}
        loop={isLoopOn}
        slidesPerView={'auto'}
        spaceBetween={20}
        onClick={handleClickEvents}
        onSlideChangeTransitionEnd={handleScrollEvents}
        onSlideChangeTransitionStart={loadRestOfItems}
        onTouchStart={loadRestOfItems}
        onScroll={(swiper: SwiperClass) => { loadRestOfItems(swiper); setIsLoopOn(true); }}
        freeMode={isNotFull ? false : true}
        mousewheel={{
          releaseOnEdges: true,
          forceToAxis: true,
        }}
        modules={[Mousewheel, FreeMode]}
      >
        {listings && !!listingsCount &&
          <SwiperSlide virtualIndex={0} key={listings[0].id} className={styles.card}>
            <ListingCard listing={listings[0]} listingToCompare={listingToCompare} isImageVisible={true} isCrawler={isCrawler} isHomepageAbTesting={isHomepageAbTesting} gtmOnClickEvent={gtmOnClickEvent} />
          </SwiperSlide>
        }
        {listings && listingsCount > 1 &&
          <SwiperSlide virtualIndex={1} key={listings[1].id} className={styles.card}>
            <ListingCard listing={listings[1]} listingToCompare={listingToCompare} isCrawler={isCrawler} isHomepageAbTesting={isHomepageAbTesting} gtmOnClickEvent={gtmOnClickEvent} />
          </SwiperSlide>
        }
        {!isMobile && (listings || []).slice(DESKTOP_INDEX_LOAD_START, calculatedDesktopLoadEnd).map((listing, index) => (
          <SwiperSlide virtualIndex={index} key={listing.id} className={styles.card}>
            <ListingCard listing={listing} listingToCompare={listingToCompare} isCrawler={index < 2 && isCrawler} isHomepageAbTesting={isHomepageAbTesting} gtmOnClickEvent={gtmOnClickEvent} />
          </SwiperSlide>
        ))}
        {carouselItems}
      </Swiper>
      {!isNotFull && <CarouselArrows isLightbox={false} isListingCardCarousel={true} slideTo={slideTo} className={styles.arrow} />}
    </div>
  );
}
